import { createBrowserRouter } from "react-router-dom";
import Error404 from "../views/error-404";
import Dashboard from "../views/dashboard";
// TODO: add case summary route
import CaseSummaryCM from "../views/case-summary";
import CommunityAssets from "../views/community-assets";

// Create a browser router and define routes
const DashboardStack = createBrowserRouter([{
    // Root route ("/")
    path: "/",
    // Renders the Auth component when the user visits the root route
    element: <Dashboard />,
    // Renders a div with "404" when an error occurs in this route
    // errorElement: <Error404 />,
},
{
    // Root route ("/")
    path: "/community-assets",
    // Renders the Auth component when the user visits the root route
    element: <CommunityAssets />,
}
]);

export default DashboardStack;