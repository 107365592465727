import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { addEditPopup, addEngagmentData, deleteEngagmentData, editEngagmentData, emmitIframeEvents, fetchAllEngagmentTracker, getEditEngagmentData, resetAddEditPopup, resetAddEngagmentData, resetDeleteEngagmentData, resetEditEngagmentData, resetEmmitIframeEvents, resetFetchAllEngagmentTracker, resetGetEditEngagmentData } from "../store/actions";
import { DropDown, DropDownMenu } from "../../../components";

import AddEngagmentPopup from "./addEngagmentPopup";
import IFrameWrapper from "./iFrameWrapper";

import KendoGrid from "../../../components/containers/kendo-grid"
import EngagmentFilterSelectPicker from "./EngagmentFilterSelectPicker";
import { GridLoader } from "../../dashboard/component";
import caseSummaryService from "../services/case-summary-service";
import CustomAction from "../../dashboard/component/customAction";
import { communityAssetsEvent, fetchChildrendDataEvent } from "../../../utils/events";
import { fetchChildGridDataById } from "../../dashboard/store/actions";
import SCVPopup from "./SelectSCVIdPopup";
import { resetPrint, printRequest } from "../store/print-document.slice";


interface PageState {
    skip: number,
    take: number
}
// Initial pagination state
const initialDataState: PageState = { skip: 0, take: 25 };

const EngagmentTrackerGrid: React.FC<any> = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();


    const childGridData = useSelector((state: any) => state.childGridData);
    const fetchAllEngagmentTrackerData = useSelector((state: any) => state.fetchAllEngagmentTrackerCM);
    const addEditEngagmentData: any = useSelector<any>((state) => state.addEditCM);
    const addEngagmentPopupData = useSelector((state: any) => state.addEngagmentCM);
    const editPopupEngagmentData = useSelector((state: any) => state.editEngagmentDataCM);
    const getEditEngagmentDetailData = useSelector((state: any) => state.getEditEngagmentCM);
    const deleteEngagmentDetailData = useSelector((state: any) => state.deleteEngagmentDetailDataCM);
    const emmitIframeEventsData = useSelector((state: any) => state.emmitIframeEvents);
    // const printDocData = useSelector((state: any) => state.printDocData);


    const [engagmentTrackerListData, setEngagmentTrackerListData] = useState([]);
    const [addEngagmentModel, setAddEngagmentModel] = useState<Boolean>(false);
    const [addEngagmentIframeModel, setAddEngagmentIframeModel] = useState<Boolean>(false);
    const [iframeConfigData, setIframeConfigData] = useState<any>(null);

    const [addEngagmentModelDataState, setAddEngagmentModelDataState] = useState<any>(null);

    const [recordsPerPage, setRecordsPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number | null>(1);
    const [dataState, setDataState] = useState<any>(initialDataState);
    const [gridData, setGridData] = useState<any>(null);
    const [hiddenCountColumns, setHiddenCountColumns] = useState<any>();
    const [editOutcomeModelDataState, setEditOutcomeModelDataState] = useState<any>(null);
    const [editCasePopup, setEditCasePopup] = useState<any>(false);

    const [selectedActionPicklistVal, setSelectedActionPicklistVal] = useState<any>("");
    const [selectedActionPicklistTitle, setSelectedActionPicklistTitle] = useState<any>("");

    const [selectedRecord, setSelectedRecord] = useState<any>(null);
    const [selectedSCVId, setSelectedSCVId] = useState<string>('');
    const [SVCData, setSVCData] = useState<any>(null);
    const [showSVCPopup, setShowSVCPopup] = useState(false);
    const [isReferral, setIsReferral] = useState(false);
    const [formData, setFormData] = useState<any>([]);

    useEffect(() => {
        // Fetch Information addCaseDetailAll
        fetchEngagmentTrackerData();
    }, [])

    useEffect(() => {
        if (engagmentTrackerListData !== fetchAllEngagmentTrackerData.data) {

            if (fetchAllEngagmentTrackerData.isSuccess && fetchAllEngagmentTrackerData.data !== null) {
                // setEngagmentTrackerListData(fetchAllEngagmentTrackerData.data)
                const masterData = fetchAllEngagmentTrackerData.data;
                const paging = masterData.paging
                const data = caseSummaryService?.processDataForKendoGrid(masterData)

                setDataState({
                    skip: (paging?.currentPage * recordsPerPage) - recordsPerPage,
                    take: recordsPerPage
                })

                setEngagmentTrackerListData(data)
                setGridData(data);


                dispatch(resetFetchAllEngagmentTracker())
            } else if (fetchAllEngagmentTrackerData.isError) {
                console.error(fetchAllEngagmentTrackerData)
                toast.error(fetchAllEngagmentTrackerData.data.error.message)
                dispatch(resetFetchAllEngagmentTracker())
                setAddEngagmentModel(false);
            }
        }

    }, [fetchAllEngagmentTrackerData]);

    useEffect(() => {
        if (emmitIframeEventsData.isSuccess && emmitIframeEventsData.data !== null) {
            fetchEngagmentTrackerData();
            dispatch(resetEmmitIframeEvents());
        } else if (emmitIframeEventsData.isError) {
            toast.error(emmitIframeEventsData?.data?.error);
            dispatch(resetEmmitIframeEvents());
        }
    }, [emmitIframeEventsData]);

    useEffect(() => {
        try {

            if (deleteEngagmentDetailData.isSuccess && deleteEngagmentDetailData.data == null) {
                // toast.success("Case Details data delete successful");
                dispatch(resetDeleteEngagmentData());
                fetchEngagmentTrackerData();
            } else if (deleteEngagmentDetailData.isError) {
                toast.error(deleteEngagmentDetailData?.data?.error);
                dispatch(resetDeleteEngagmentData());
            }
        } catch (e: any) {
            toast.error(e.message);
        }
    }, [deleteEngagmentDetailData])

    useEffect(() => {
        // Process received getEditEngagmentDetailData data
        if (getEditEngagmentDetailData?.data !== editOutcomeModelDataState) {
            try {
                if (getEditEngagmentDetailData?.isSuccess && getEditEngagmentDetailData?.data !== null) {

                    const data = getEditEngagmentDetailData?.data;
                    if (data[0].type === "iframe") {
                        const controls = data[0];
                        setIframeConfigData(controls);
                        setAddEngagmentIframeModel(true);
                    } else {
                        setEditOutcomeModelDataState(getEditEngagmentDetailData.data);
                        setEditCasePopup(true);
                    }


                    dispatch(resetGetEditEngagmentData());
                } else if (getEditEngagmentDetailData?.isError) {
                    // toast.error("Unable to fetch record details."); // TODO: to be worked on this entire logic again 
                    dispatch(resetGetEditEngagmentData());
                }
            } catch (e: any) {
                toast.error(e.message);
            }
        }
    }, [getEditEngagmentDetailData]);

    useEffect(() => {
        // Process received editPopupEngagmentData data
        try {
            if (editPopupEngagmentData.isSuccess && editPopupEngagmentData.data !== null) {
                // toast.success("Case Details edit successful");
                setEditCasePopup(false);
                fetchEngagmentTrackerData();
                dispatch(resetEditEngagmentData());
            } else if (editPopupEngagmentData.isError) {
                toast.error(editPopupEngagmentData?.data?.error);
                setEditCasePopup(false);
                fetchEngagmentTrackerData();
                dispatch(resetEditEngagmentData());
            }
        } catch (e: any) {
            toast.error(e.message);
        }

    }, [editPopupEngagmentData]);



    useEffect(() => {
        // Process received addOutcomeModel data
        if (addEditEngagmentData.isSuccess && addEditEngagmentData.data !== null) {
            const data = addEditEngagmentData.data.popupForm;
            setIsReferral(false);
            const controls = data.controls[0];

            if (data.controls[0].type === "iframe") {
                if (controls.isScvIdRequired) {
                    dispatch(fetchChildGridDataById({
                        token: props.token,
                        param: {
                            gridId: props.gridId,
                            oneViewReference: props.selectedCaseReference,
                            dspId: props.dsp,
                        }
                    }));
                } else {
                    setAddEngagmentIframeModel(true);
                }

                fetchEngagmentTrackerData();
                setIframeConfigData(controls);

            } else if (data.controls[0].type === "communityassets") {
                if (controls.isScvIdRequired) {
                    setIsReferral(true);
                    dispatch(fetchChildGridDataById({
                        token: props.token,
                        param: {
                            gridId: props.gridId,
                            oneViewReference: props.selectedCaseReference,
                            dspId: props.dsp,
                        }
                    }));
                } else {
                    window.dispatchEvent(communityAssetsEvent)
                }

            }
            else {
                setAddEngagmentModelDataState(addEditEngagmentData.data);
                setAddEngagmentModel(true);
                setIsReferral(false);
            }

            dispatch(resetAddEditPopup());
        } else if (addEditEngagmentData.isError) {
            toast.error(addEditEngagmentData?.data?.error?.data);
            dispatch(resetAddEditPopup());
            setAddEngagmentIframeModel(false);
            setAddEngagmentModel(false);
            setShowSVCPopup(false);
        }
    }, [addEditEngagmentData]);


    useEffect(() => {
        if (childGridData.isSuccess && childGridData.data !== null) {
            if (childGridData.data.rowCount > 0) {
                if (isReferral) {
                    if (childGridData.data.rowCount > 1) {
                        setSVCData(childGridData.data);
                        setShowSVCPopup(true);
                    } else if (childGridData.data.rowCount === 1) {
                        communityAssetsEvent.detail.selectedSCVId = childGridData.data.gridRows[0].values[1];
                        window.dispatchEvent(communityAssetsEvent)
                    }
                } else {
                    if (childGridData.data.rowCount > 1) {
                        setSVCData(childGridData.data);
                        setShowSVCPopup(true);
                    } else if (childGridData.data.rowCount === 1) {
                        setSelectedSCVId(childGridData.data.gridRows[0].values[1]);
                        setAddEngagmentIframeModel(true);
                    }
                }

            }
            else {
                toast.error("No SCV Id found");
            }
        } else if (childGridData.isError) {
            toast.error(childGridData?.data?.error);
        }
    }, [childGridData]);


    useEffect(() => {
        try {
            if (addEngagmentPopupData.isSuccess && addEngagmentPopupData.data !== null) {
                // toast.success("Case Details add successful");
                setAddEngagmentModel(false);
                setAddEngagmentModelDataState(null);
                fetchEngagmentTrackerData();
                dispatch(resetAddEngagmentData());
            } else if (addEngagmentPopupData.isError) {
                toast.error(addEngagmentPopupData?.data?.error);
                setAddEngagmentModel(false);
                setAddEngagmentModelDataState(null);
                dispatch(resetAddEngagmentData());
            }
        } catch (e: any) {
            toast.error(e.message);
        }
    }, [addEngagmentPopupData]);


    // useEffect(() => {
    //     if (printDocData.isSuccess) {
    //         console.log ("success")
    //         dispatch(resetPrint())
    //     }else if (printDocData.isError) {
    //         dispatch(resetPrint())
    //     }
    // }, [printDocData])


    const __onCustomAction = (res: any) => {
        try {
            switch (res.action) {
                case "delete":
                    dispatch(deleteEngagmentData({
                        token: props.token,
                        param: {
                            controlId: res.data.linkFormControlId,
                            dspId: props.dsp,
                            id: res.data.linkDataId
                        }
                    }))
                    break;
                case "edit":
                    setSelectedRecord(res.data);
                    setSelectedSCVId(res.data.scvid);
                    dispatch(
                        getEditEngagmentData({
                            token: props.token,
                            param: {
                                // controlId: res.dataItem.Activitytype,
                                controlId: res.data.linkFormControlId,
                                dspId: props.dsp,
                                id: res.data.linkDataId,
                            },
                        }));
                    break;
                default:
                    break;

            }
            setAddEngagmentModel(false);
            setAddEngagmentModelDataState(null);
        } catch (res) {
            console.log(res);
        }
    }

    const handleChange = (e: any) => {
        const { value, id } = e.target;

        const tempForm = [...formData];

        const index = tempForm.findIndex((item: any) => item.controlId === id);

        if (index > -1) {

            if (value === "" || value === null) {
                tempForm.splice(index, 1);
            } else {
                tempForm[index].values = value;
            }


        } else {
            tempForm.push({
                controlId: id,
                values: value,
            });
        }

        setFormData(tempForm);
    };


    const fetchEngagmentTrackerData = () => {
        try {
            dispatch(
                fetchAllEngagmentTracker({
                    token: props.token,
                    param: {
                        dspId: props.dsp,
                        oneViewReference: props.selectedCaseReference,
                        controlId: props.tabs.datagrid.dataGridId
                    },
                    body: formData,
                })
            );
        } catch (e: any) {
            toast.error(e.message);
        }
    }



    const __onPageChange = (res: any) => {
        setRecordsPerPage(res.page.take)
        let page = (res.page.skip / res.page.take) + 1
        setCurrentPage(page)
    }

    const renderEngagmentTrackerList = () => {
        return (
            gridData !== null ? (
                <KendoGrid
                    gridData={engagmentTrackerListData}
                    pageSize={recordsPerPage}
                    getEditEngagmentDetailData={getEditEngagmentDetailData}
                    token={props.token}
                    dsp={props.dsp}
                    selectedCaseReference={props.selectedCaseReference}
                    fetchEngagmentTrackerData={fetchEngagmentTrackerData}
                    onPageChange={__onPageChange}
                    // fetchCaseNotesData={fetchCaseDetailsData}
                    dataState={dataState}
                    expandField=""
                    isSelectable={false}
                    isCustomAction={__IsCustomAction()}
                    customeActionLayout={__renderCustomActionLayout}
                />) : null
        )
    }


    const __IsCustomAction = () => {
        if (props.tabs.datagrid.allowEdit || props.tabs.datagrid.allowDelete) {
            return true;
        } else {
            return false;
        }
    }

    const __renderCustomActionLayout = (res: any) => {
        return <CustomAction
            data={res}
            allowDetails={false}
            allowEdit={res.actionEdit === "1"}
            allowDelete={res.actionDelete === "1"}
            onClick={(e: any) => __onCustomAction(e)}
        />
    }


    const __fetchPopupConfig = (controlId: any) => {
        try {
            // Fetch Information add_edit_cofig
            dispatch(
                addEditPopup({
                    token: props.token,
                    param: {
                        dspId: props.dsp,
                        oneViewReference: props.selectedCaseReference,
                        controlId: controlId,
                    },
                })
            );
        } catch (e: any) {
            toast.error(e.message);
        }

    }


    const __fetchPrintDoucments = (res: any) => {
        try {
            // "On selection the following call should be made api/pdf?oneviewReference={nnnnn}&printDocType={selectedId}&dspId={nnnn}"
            // Fetch Information add_edit_cofig
            const obj: any = {
                token: props.token,
                param: {
                    dspId: props.dsp,
                    oneViewReference: props.selectedCaseReference,
                    printDocType: res.value,
                    printDocName: res.title
                }
            }
            dispatch(
                printRequest(obj)
            );
        } catch (e: any) {
            toast.error(e.message);
        }

    }
    const __selectActionPicklist = (e: any, type: string) => {
        type = type.toLowerCase()

        if (type === "engagementtrackerprintactionbutton") {
            __fetchPrintDoucments(e)

        } else if (type === "engagementactionlist") {

            setSelectedActionPicklistVal(e.value);
            setSelectedActionPicklistTitle(e.title);

            setAddEngagmentIframeModel(false);
            setAddEngagmentModel(false);
            setShowSVCPopup(false);

            setSVCData(null);
            setSelectedSCVId('');

            __fetchPopupConfig(e.value);
        }
        

    }

    // Function to render layout based on item type
    const __renderToolbarLayout = () => {
        let itemsToRender: any = [];
        let actionPicklist: any = [];

        props?.tabs?.datagrid?.toolbar?.controls.map((items: any, index: any) => {
            if (!items.visible) {
                return 
            }

            switch (items.type) {
                case "selectpicker":
                    itemsToRender.push(
                        <EngagmentFilterSelectPicker
                            formData={formData}
                            hidden={!items.visible}
                            token={props.token}
                            dspId={props.dspId}
                            items={items}
                            handleChange={handleChange}
                        />
                    );
                    break;
                case "button":
                    itemsToRender.push(
                        <div
                            className=""
                            key={items.controlId}>
                            <button
                                className={items.name !== "clearButton" ? "btn btn-secondary btn-info-filter min-width6 close" : "btn btn-secondary btn-info-close min-width6 close"}

                                type={items.name === "clearButton" ? "reset" : "submit"}
                                key={items.controlId}
                                onClick={() => {
                                    if (items.name == "clearButton") {
                                        setFormData([]);
                                    }
                                }}
                            >{items.label}</button>
                        </div>
                    );
                    break;
                case "clear":
                    itemsToRender.push(
                        <div
                            className=""
                            key={items.controlId}>
                            <button
                                className={"btn btn-secondary btn-info-close min-width6 close"}
                                key={items.controlId}
                                type="reset"
                            >
                                {items.label}
                            </button>
                        </div>
                    );
                    break;
                case "actionPicklist":
                    let arrForDropdown = items.picklistValues.map((obj: any) => {
                        return {
                            id: obj.valueToStore,
                            title: obj.textToDisplay,
                            value: obj.valueToStore
                        }
                    });

                    actionPicklist.push(
                        <div className="button-wrapper mx-1" style={{ width: "100%" }}>
                            <DropDownMenu
                                label={items.label}
                                labelExternal={false}
                                key={"filters"}
                                name={"name"}
                                data={arrForDropdown}
                                defaultSelected={selectedActionPicklistVal}
                                isMultiSelectPickList={false}
                                onChange={(e) => __selectActionPicklist(e, items.name)}
                            /></div>
                    );
                    break;
                default:
                    return null;
            }
        })

        return <div className="row">
            <div className="col-md-10 col-lg-10" key={"engagement-filters"}>
                <form className="d-flex" onSubmit={(e) => {
                    e.preventDefault();

                    fetchEngagmentTrackerData();
                }}>
                    {itemsToRender}
                </form>
            </div>
            <div className="col-md-2 col-lg-2 d-flex">
                {actionPicklist}
            </div>
        </div>;
    };

    const __submitSCVId = (type: string, data: string | null) => {
        try {
            if (data === null || type === "closeForm") {
                setShowSVCPopup(false);
            } else {
                if (isReferral) {
                    setSelectedSCVId(data);

                    communityAssetsEvent.detail.selectedSCVId = data;
                    window.dispatchEvent(communityAssetsEvent);
                    setShowSVCPopup(false);
                } else {
                    setSelectedSCVId(data);
                    setShowSVCPopup(false);
                    setAddEngagmentIframeModel(true);
                }

            }
        } catch (e) {
            toast.error("Error in fetching SCV Id");
        }
    }

    const __submitIframeModel = (type: string, data: any) => {
        setAddEngagmentIframeModel(false);
    }

    const __submitIgPluginAuth = (type: string, data: any) => {
        try {
            if (type === "closeForm") {
                setAddEngagmentModel(false);
            } else if (type === "formSubmit") {

                /**
                 * Dispatch action to Add outcome data
                 **/
                let convertedArray: any = [];
                for (const key in data) {
                    if (data[key] !== '') {
                        convertedArray.push({
                            "controlName": key,
                            "values": data[key]
                        })
                    }
                }
                try {
                    dispatch(
                        addEngagmentData({
                            token: props.token,
                            param: {
                                dspId: +props.dsp,
                                oneViewReference: props.selectedCaseReference,
                                controlId: addEngagmentModelDataState.popupForm.controls[0].associatedControlId,
                            },
                            body: convertedArray,
                        })
                    );
                } catch (e: any) {
                    toast.error(e.message);
                }

            }
        } catch (e: any) {
            toast.error(e.message);
        }
    };


    const __submitEditCaseRecord = (type: string, data: any,) => {

        try {
            if (type === "closeForm") {
                setEditCasePopup(false);
                // setEditOutcomeModelDataState(null);
            } else if (type === "formSubmit") {
                let convertedArray: any = [];
                for (const key in data) {
                    if (data[key] !== '' && key !== 'Id' && key !== 'GroupRef') {
                        convertedArray.push({
                            "controlName": key,
                            "values": data[key]
                        })
                    }
                }
                try {
                    dispatch(
                        editEngagmentData({
                            token: props.token,
                            param: {
                                dspId: parseInt(props.dsp),
                                oneViewReference: props.selectedCaseReference,
                                controlId: editOutcomeModelDataState[0].associatedControlId,
                                id: selectedRecord.linkDataId,
                            },
                            body: convertedArray,
                        })
                    );
                } catch (e: any) {
                    toast.error(e.message);
                }

            }
        } catch (e: any) {
            toast.error(e.message);
        }
    }

    const emmitEvents = (type: string, res: any) => {
        let params: any = {
            dspId: props.dsp,
            oneViewReference: props.selectedCaseReference,
            iFrameUrl: res.url,
            controlId: iframeConfigData.controlId
        }

        if (iframeConfigData.addRecordApiEndPoint.parameters.includes("scvId")) {
            params["scvId"] = selectedSCVId.toString();
        }

        dispatch(emmitIframeEvents({
            token: props.token,
            url: iframeConfigData.addRecordApiEndPoint.endpointUrl.slice(4),
            param: params
        }));

        if (type === "complete") {
            setAddEngagmentIframeModel(false);
        }
    }

    return (
        <div className="outcome-grid">
            <div className="filter-content-ET pos-relative">
                <div className="engagement-header">
                    {__renderToolbarLayout()}
                </div>
            </div>
            {fetchAllEngagmentTrackerData.isFetching ? <GridLoader /> : renderEngagmentTrackerList()}
            {/* {renderEngagmentTrackerList()} */}

            {(addEngagmentModel) &&
                <AddEngagmentPopup
                    token={props.token}
                    dspId={props.dsp}
                    title={selectedActionPicklistTitle}
                    data={addEngagmentModelDataState}
                    isFetching={false}
                    onSubmit={(type: string, res: any) => {
                        if (res === null) {
                            __submitIgPluginAuth("closeForm", res)
                        }
                        else {
                            __submitIgPluginAuth(type, res)
                        }
                    }}
                />}

            {(addEngagmentIframeModel) &&
                <IFrameWrapper
                    token={props.token}
                    title={iframeConfigData.label}
                    data={iframeConfigData}
                    param={{
                        dspId: props.dsp,
                        oneViewReference: props.selectedCaseReference,
                        scvid: selectedSCVId
                    }}
                    onSubmit={(type: string, res: any) => {
                        if (res === null) {
                            __submitIframeModel("closeForm", res)
                        }
                    }}

                    emmitEvents={emmitEvents}
                />}

            {(showSVCPopup) &&
                <SCVPopup
                    title={"Select SCV Id"}
                    data={SVCData}
                    onSubmit={__submitSCVId}
                />}

            {editCasePopup && <AddEngagmentPopup
                token={props.token}
                dspId={props.dsp}
                data={{ popupForm: { controls: editOutcomeModelDataState, selectedRecord } }}
                isFetching={false}
                onSubmit={(type: string, res: any) => {
                    if (res === null) {
                        __submitEditCaseRecord("closeForm", res)
                    } else {
                        __submitEditCaseRecord(type, res)
                    }

                }}
            />}
        </div >
    );
};

export default EngagmentTrackerGrid;
