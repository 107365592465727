// Importing action types from the types file
import * as types from "../types";

// Setting the initial state for this reducer
const INITIAL_STATE = { data: null };

/**
 * A Redux reducer that handles actions related to case data.
 * 
 * @param {object} state - The current state of the application.
 * @param {object} action - An action dispatched from the application.
 * @returns {object} - The new state of the application.
 */
export default function (state = INITIAL_STATE, action: any) {
    // Using a switch statement to handle different action types
    switch (action.type) {
        case types.CASE_DATA_CM_UPDATE:
            // Initialize an empty data object
            let data: any = {};

            // Check if state.data is not null, deep clone it to prevent mutation
            if (state.data !== null) {
                data = JSON.parse(JSON.stringify(state.data));
            }

            // Update the data object based on whether an error exists
            if (action.payload?.isError) {
                // If there's an error, populate emptyMessage field in the data object
                data[action.payload.data.dataId] = { emptyMessage: action.payload.data.error.message };
            } else {
                // Else, directly assign the payload data to the corresponding dataId
                data[action.payload.dataId] = action.payload.data;
            }

            // Return the new state with updated data
            return {
                ...state,
                data: data
            };
        case types.RESET_CASE_DATA_CM:
            // Reset the data field to null
            return {
                ...state,
                data: null,
            };
        default:
            // Return the existing state if action type doesn't match
            return {
                ...state
            };
    }
}