import { t } from "i18next";
import { parseDataByType } from "../../../utils";
import { toast } from "react-toastify";


const processDataForKendoGrid = (resData: any) => {
    try {
        let processedData: any = [];

        if (resData.gridRows.length > 0) {
            resData.gridRows.forEach((res: any, index: number) => {
                let obj: any = {};
                // obj[DATA_ITEM_KEY] = index;
                obj["id"] = index;
                obj["SELECTED_FIELD"] = '';

                res.values.forEach((tdRes: any, tdIndex: number) => {
                    const colDetails = resData.gridColumns[tdIndex];
                    obj[colDetails.name.trim()] = parseDataByType(tdRes, colDetails.dataType);
                });

                
                processedData.push(obj);
            })
        }
        
        return { ...resData, gridRows: processedData }

    } catch (e) {
        console.log(e)
    }
}

const processDataForKendoGridChildData = (resData: any) => {
    try {
        let processedData: any = [];

        if (resData.gridRows.length > 0) {
            resData.gridRows.forEach((res: any, index: number) => {
                let obj: any = {};
                obj["id"] = index;

                res.values.forEach((tdRes: any, tdIndex: number) => {
                    
                    const colDetails = resData.gridColumns[tdIndex];
                    if (colDetails!== undefined){
                        obj[colDetails.name.trim()] = parseDataByType(tdRes, colDetails.dataType);
                    }else {
                        toast.info(t("Error mapping column details. Please contact support."));
                    }
                    
                });

                processedData.push(obj);
            })
        }

        return { ...resData, gridRows: processedData }
    } catch (e) {
        console.log(e)
    }
}

export default { processDataForKendoGrid, processDataForKendoGridChildData }